.document-management-table table .md-form {
    margin-bottom: 0;
}

.document-management-table table.table td {
    vertical-align: middle;
}

.document-management-table td:first-of-type,
.document-management-table th:first-of-type {
    width: 50px;
}

.document-management-table td:nth-of-type(2),
.document-management-table th:nth-of-type(2) {
    width: 50px;
    vertical-align: middle;
    text-align: center;
}

.document-management-table td:nth-of-type(2) {
    color: #999999;
}

.document-management-table td:nth-of-type(3) {
    padding: 0 15px 0 15px;
}

.document-management-table td:nth-of-type(4),
.document-management-table th:nth-of-type(4) {
    width: 18%;
}

.document-management-table td:nth-of-type(4) {
    padding-left: 23px;
}


.document-management-table td:last-of-type,
.document-management-table th:last-of-type {
    width: 12%;
}

.document-management-table td:last-of-type {
    color: #999999;
}
