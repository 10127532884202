.report-preview {
    position: relative;
    max-width: 1000px;
    overflow: auto;
}

.ExcelTable {
    border: 1px solid #b0cbef;
    border-width: 1px 0px 0px 1px;
    font-size: 11pt;
    font-family: Calibri;
    font-weight: 100;
    border-spacing: 0px;
    border-collapse: collapse;
    padding: 10px;
}

.ExcelTable th {
    background-image: url(report-preview-header.gif);
    background-repeat: repeat-x;
    font-weight: bold;
    font-size: 14px;
    border: 1px solid #9eb6ce;
    border-width: 0px 1px 1px 0px;
    height: 17px;
    line-height: 17px;
   
}


.ExcelTable td {
    border: 0px;
    background-color: white;
    padding: 0px 4px 0px 2px;
    border: 1px solid #d0d7e5;
    border-width: 0px 1px 1px 0px;
   
}

.ExcelTable tr th:not(:first-child){
    min-width: 100px;
    max-width: 100px;
    word-wrap: normal;
}
.ExcelTable tr td:not(:first-child){
    min-width: 100px;
    max-width: 100px;
    word-wrap: normal;
}
.ExcelTable td b {
    border: 0px;
    background-color: white;
    font-weight: bold;
}

.ExcelTable td.heading {
    background-color: #e4ecf7;

    text-align: center;
    border: 1px solid #9eb6ce;
    border-width: 0px 1px 1px 0px;
    font-weight: bold;
}

.ExcelTable th.heading {
    background-repeat: none;
   
}

.ExcelTable tr td:last-child {
    display: none;
}

th {
    text-align: center;
}
