.sideBarlinkImage {
    color: #c0ced0;
}

.sideBarlinkImage:hover {
    color: #108393;
}

.sideBarlinkText {
    color: #7c7c7c;
}

.sideBarlinkText:hover {
    color: #108393;
}

.sideBarlinkPressed {
    color: #108393;
}

.sideBarlinkContainerPressed {
    color: #108393;
    background: rgba(16, 131, 147, 0.05);
    border-right: 4px ridge #108393;
}

.sideBarDocumentList {
    list-style: none;
    width: 100%;
}

.sideBarDocumentList li {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
}

.sideBarDocumentList li span {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
    font-size: 0.85rem;
}

.sideBarDocumentList li span:hover {
    text-decoration: none;
}

.fa-angle-down {
    color: #c0ced0;
}

.fa-angle-up {
    color: #c0ced0;
}

.card-header {
    border-bottom: none;
    background-color: white;
}

.refreshDocumentButton:hover {
    color: rgb(130, 130, 130);
}
